import fetch from '../utils/fetch'

export function getMembers (query) {
  return fetch({
    url: '/members',
    method: 'get',
    params: query
  })
}
export function getMember (query) {
  return fetch({
    url: '/member',
    method: 'get',
    params: query
  })
}

export function setMemberRegisterSetting (query) {
  return fetch({
    url: '/members/register/setting',
    method: 'post',
    data: query
  })
}

export function setMemberRegisterDistiSetting (query) {
  return fetch({
    url: '/members/register/disti/setting',
    method: 'post',
    data: query
  })
}

export function setMemberRegisterContentSetting (query) {
  return fetch({
    url: '/members/register/setting',
    method: 'post',
    data: query
  })
}

export function setMemberRegisterContentDistiSetting (query) {
  return fetch({
    url: '/members/register/disti/setting',
    method: 'post',
    data: query
  })
}

export function setMemberanalysisrightsContent (query) {
  return fetch({
    url: '/members/analysisrights/content',
    method: 'post',
    data: query
  })
}

export function getMemberanalysisrightsContent () {
  return fetch({
    url: '/members/analysisrights/content',
    method: 'get'
  })
}

export function getMemberRegisterSetting (query) {
  return fetch({
    url: '/members/register/setting',
    method: 'get',
    params: query
  })
}

export function getMemberRegisterDistiSetting (query) {
  return fetch({
    url: '/members/register/disti/setting',
    method: 'get',
    params: query
  })
}

export function memberSmsSend (query) {
  return fetch({
    url: '/member/smssend',
    method: 'post',
    data: query
  })
}

export function saveTag (query) {
  return fetch({
    url: '/member/tag',
    method: 'post',
    data: query
  })
}

export function getTagList (query) {
  return fetch({
    url: '/member/tag',
    method: 'get',
    params: query
  })
}

export function updateMemberMobile (query) {
  return fetch({
    url: '/member',
    method: 'put',
    params: query
  })
}

export function setSindusersalespersonrel (query) {
  return fetch({
    url: '/member/bindusersalespersonrel',
    method: 'post',
    data: query
  })
}

export function updateMemberGrade (query) {
  return fetch({
    url: '/member/grade',
    method: 'put',
    params: query
  })
}

export function batchupdateMemberGrade (query) {
  return fetch({
    url: '/member/grade',
    method: 'patch',
    params: query
  })
}

export function updateMemberInfo (query) {
  return fetch({
    url: '/member',
    method: 'patch',
    params: query
  })
}

export function getMemberOperateLog (query) {
  return fetch({
    url: '/operate/loglist',
    method: 'get',
    params: query
  })
}

export function confirmCloseUser (query) {
  return fetch({
    url: '/member/confirmCloseUser',
    method: 'put',
    data: query
  })
}

//refuseCloseUser
export function refuseCloseUser (query) {
  return fetch({
    url: '/member/refuseCloseUser',
    method: 'put',
    data: query
  })
}

export function getTagInfo (tagId) {
  return fetch({
    url: '/member/tag/' + tagId,
    method: 'get'
  })
}

export function updateTag (query) {
  return fetch({
    url: '/member/tag',
    method: 'put',
    data: query
  })
}

export function deleteTag (tagId) {
  return fetch({
    url: '/member/tag/' + tagId,
    method: 'delete'
  })
}

export function usersRelTagsDel (query) {
  return fetch({
    url: '/member/reltagdel/',
    method: 'post',
    data: query
  })
}

export function tagSearchUser (query) {
  return fetch({
    url: '/member/tagsearch',
    method: 'GET',
    params: query
  })
}

export function listVipGrade (query) {
  return fetch({
    url: '/vipgrade/order',
    method: 'GET',
    params: query
  })
}

export function memberExport(query) {
  return fetch({
    url: '/member/export',
    method: 'GET',
    params: query
  })
}

export function salespersonBindExport(query) {
  return fetch({
    url: '/salespersonbind/export',
    method: 'GET',
    params: query
  })
}

export function createTagCategory (query) {
  return fetch({
    url: '/member/tagcategory',
    method: 'post',
    data: query
  })
}

export function getTagCategoryList (query) {
  return fetch({
    url: '/member/tagcategory',
    method: 'get',
    params: query
  })
}

export function getTagCategoryInfo (categoryId) {
  return fetch({
    url: '/member/tagcategory/' + categoryId,
    method: 'get'
  })
}

export function updateTagCategory (categoryId, query) {
  return fetch({
    url: '/member/tagcategory/' + categoryId,
    method: 'put',
    data: query
  })
}

export function deleteTagCategory (categoryId) {
  return fetch({
    url: '/member/tagcategory/' + categoryId,
    method: 'delete'
  })
}

export function batchOperating(query) {
  return fetch({
    url: '/member/batchOperating',
    method: 'POST',
    data: query
  })
}

export function updateMemberBasicInfo (query) {
  return fetch({
    url: '/member/update',
    method: 'put',
    data: query
  })
}

export function getMembersWhitelistList (query) {
  return fetch({
    url: '/members/whitelist/list',
    method: 'get',
    params: query
  })
}

export function createMembersWhitelist (query) {
  return fetch({
    url: '/members/whitelist',
    method: 'post',
    data: query
  })
}

export function updateMembersWhitelist (id, query) {
  return fetch({
    url: '/members/whitelist/' + id,
    method: 'post',
    data: query
  })
}

export function deleteMembersWhitelist (id) {
  return fetch({
    url: '/members/whitelist/' + id,
    method: 'delete',
  })
}

export function getSubscribeList (query) {
  return fetch({
    url: '/members/subscribe/list',
    method: 'get',
    params: query
  })
}

export function setAutoConfirmCloseUser(query) {
  return fetch({
    url: '/members/autoconfirmcloseuser/config',
    method: 'POST',
    data: query
  })
}

export function getAutoConfirmCloseUser (query) {
  return fetch({
    url: '/members/autoconfirmcloseuser/config',
    method: 'get',
    params: query
  })
}

export function getwechatuserofflist (query) {
  return fetch({
    url: '/members/getwechatuserofflist',
    method: 'get',
    params: query
  })
}

export function setwechatuseroffdone (query) {
  return fetch({
    url: '/members/setwechatuseroffdone',
    method: 'POST',
    data: query
  })
}


export function setagreementinfo (query) {
  return fetch({
    url: '/members/setagreementinfo',
    method: 'POST',
    data: query
  })
}

export function getagreementinfo (query) {
  return fetch({
    url: '/members/getagreementinfo',
    method: 'get',
    params: query
  })
}


export function setUserSetting (query) {
  return fetch({
    url: '/members/setusersetting',
    method: 'POST',
    data: query
  })
}

export function getUserSetting (query) {
  return fetch({
    url: '/members/getusersetting',
    method: 'get',
    params: query
  })
}

export function getDeleteApplyRecord (query) {
  return fetch({
    url: '/members/deleteApplyRecord/list',
    method: 'get',
    params: query
  })
}

export function getIsAutoAudit (query) {
  return fetch({
    url: '/members/deleteApplyRecord/getIsAutoAudit',
    method: 'get',
    params: query
  })
}

export function setIsAutoAudit (params) {
  return fetch({
    url: '/members/deleteApplyRecord/setIsAutoAudit',
    method: 'POST',
    data: params
  })
}

export function agreeApply (params) {
  return fetch({
    url: '/members/deleteApplyRecord/agree',
    method: 'POST',
    data: params
  })
}

export function refuseApply (params) {
  return fetch({
    url: '/members/deleteApplyRecord/refuse',
    method: 'POST',
    data: params
  })
}

export function batchAgreeApply (params) {
  return fetch({
    url: '/members/deleteApplyRecord/batchAgree',
    method: 'POST',
    data: params
  })
}

export function batchRefuseApply (params) {
  return fetch({
    url: '/members/deleteApplyRecord/batchRefuse',
    method: 'POST',
    data: params
  })
}

export function deleteApplyRecord (params) {
  return fetch({
    url: '/members/deleteApplyRecord/deleteRecord',
    method: 'POST',
    data: params
  })
}

export function batchAgreeUploadApply (params) {
  return fetch({
    url: '/members/deleteApplyRecord/batchAgreeUploadApply',
    method: 'POST',
    data: params
  })
}
// 添加会员渠道
export function createMemberChannel (data) {
  return fetch({
    url: '/members_vip/channel/create',
    method: 'post',
    data
  })
}

//获取渠道列表
// channel_id=&channel_name=&page=&pageSize=
export function getMemberChannelList (params) {
  return fetch({
    url: 'members_vip/channel/lists',
    method: 'GET',
    params
  })
}