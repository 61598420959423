import fetch from '../utils/fetch'

// 获取赠品列表
export function getLotteryGiftList(query) {
  return fetch({
    url: '/lottery/gift/list',
    method: 'get',
    params: query
  })
}

// 添加编辑赠品
export function getLotteryGiftInfo(params) {
  return fetch({
    url: '/lottery/gift/info',
    method: 'post',
    data: params
  })
}

// 库存记录
export function getLotteryGiftStockLog(params) {
  return fetch({
    url: '/lottery/gift/stock/log',
    method: 'get',
    params: params
  })
}

// 门店赠品库存列表
export function getLotteryGiftShopstock(params) {
  return fetch({
    url: '/lottery/gift/shop_stock',
    method: 'get',
    params: params
  })
}

// 修改门店库存
export function getLotteryGiftStock(params) {
  return fetch({
    url: '/lottery/gift/stock',
    method: 'put',
    params: params
  })
}

// 修改限定商品的库存
export function getLotteryGiftTotalStock(params) {
  return fetch({
    url: '/lottery/gift/total_stock',
    method: 'put',
    params: params
  })
}

