import fetch from '../utils/fetch'

// 基础物料
export function getServiceLabelsList (query) {
  return fetch({
    url: '/goods/servicelabels',
    method: 'get',
    params: query
  })
}

export function getServiceLabelsDetail (labelId) {
  return fetch({
    url: '/goods/servicelabels/' + labelId,
    method: 'get'
  })
}

export function createServiceLabels (query) {
  return fetch({
    url: '/goods/servicelabels',
    method: 'post',
    data: query
  })
}

export function updateServiceLabels (labelId, query) {
  return fetch({
    url: '/goods/servicelabels/' + labelId,
    method: 'put',
    data: query
  })
}

export function deleteServiceLabels (labelId) {
  return fetch({
    url: '/goods/servicelabels/' + labelId,
    method: 'delete'
  })
}

// 商品类型
export function getItemsTypeList (query) {
  return fetch({
    url: '/goods/itemstype',
    method: 'get',
    params: query
  })
}

export function getItemsTypeDetail (typeId) {
  return fetch({
    url: '/goods/itemstype/' + typeId,
    method: 'get'
  })
}

export function getItemsTypeLabelsDetail (typeId) {
  return fetch({
    url: '/goods/itemstypelabels/' + typeId,
    method: 'get'
  })
}

export function createItemsType (query) {
  return fetch({
    url: '/goods/itemstype',
    method: 'post',
    data: query
  })
}

export function updateItemsType (typeId, query) {
  return fetch({
    url: '/goods/itemstype/' + typeId,
    method: 'put',
    data: query
  })
}

export function deleteItemsType (typeId) {
  return fetch({
    url: '/goods/itemstype/' + typeId,
    method: 'delete'
  })
}

// 商品
export function getItemsList (query) {
  return fetch({
    url: '/goods/items',
    method: 'get',
    params: query
  })
}

export function getSkuList (query) {
  return fetch({
    url: '/goods/sku',
    method: 'get',
    params: query
  })
}

export function getItemsDetail (itemId) {
  return fetch({
    url: '/goods/items/' + itemId,
    method: 'get'
  })
}

export function createItems (query) {
  return fetch({
    url: '/goods/items',
    method: 'post',
    data: query,
    refreshToken: true
  })
}

export function updateItems (itemId, query) {
  return fetch({
    url: '/goods/items/' + itemId,
    method: 'put',
    data: query,
    refreshToken: true
  })
}

export function setItemsTemplate (params) {
  return fetch({
    url: '/goods/setItemsTemplate',
    method: 'post',
    data: params
  })
}

export function setItemsSort (params) {
  return fetch({
    url: '/goods/setItemsSort',
    method: 'post',
    data: params
  })
}

export function setItemsCategory (params) {
  return fetch({
    url: '/goods/setItemsCategory',
    method: 'post',
    data: params
  })
}

export function deleteItems (itemId) {
  return fetch({
    url: '/goods/items/' + itemId,
    method: 'delete'
  })
}

export function getDistributionGoodsWxaCode (query) {
  return fetch({
    url: '/goods/distributionGoodsWxaCodeStream',
    method: 'get',
    params: query
  })
}

export function getItemWarningStore (query) {
  return fetch({
    url: '/goods/warning_store',
    method: 'post',
    data: query
  })
}

export function getCategory (query) {
  return fetch({
    url: '/goods/category',
    method: 'get',
    params: query
  })
}

export function saveCategory (params) {
  return fetch({
    url: '/goods/category',
    method: 'post',
    data: params
  })
}

export function deleteCategory (categoryId) {
  return fetch({
    url: '/goods/category/' + categoryId,
    method: 'delete'
  })
}

export function updateCategory (categoryId, query) {
  return fetch({
    url: '/goods/category/' + categoryId,
    method: 'put',
    data: query
  })
}

export function getCategoryInfo (categoryId) {
  return fetch({
    url: '/goods/category/' + categoryId,
    method: 'get'
  })
}

export function getGoodsAttr (query) {
  return fetch({
    url: '/goods/attributes',
    method: 'get',
    params: query
  })
}

export function addGoodsAttr (query) {
  return fetch({
    url: '/goods/attributes',
    method: 'post',
    data: query
  })
}

export function updateGoodsAttr (query) {
  return fetch({
    // url: '/goods/attributes/add' + attributes_id,
    url: 'goods/attribute_value/add',
    method: 'post',
    data: query
  })
}

export function deleteGoodsAttr (attributesId) {
  return fetch({
    url: '/goods/attributes/' + attributesId,
    method: 'delete'
  })
}

export function updateGoodsInfo (query) {
  return fetch({
    url: '/goods/itemsupdate',
    method: 'put',
    data: query
  })
}

export function getGoodsPrice (id) {
  return fetch({
    url: '/goods/memberprice/' + id,
    method: 'get'
  })
}

export function getGoodsProfitPrice (id) {
  return fetch({
    url: '/goods/profit/' + id,
    method: 'get'
  })
}

export function saveGoodsProfitPrice (params) {
  return fetch({
    url: '/goods/profit/save',
    method: 'post',
    data: params
  })
}

export function saveCategoryProfitPrice (params) {
  return fetch({
    url: '/goods/category/profit/save',
    method: 'post',
    data: params
  })
}

export function updateGoodsPrice (query) {
  return fetch({
    url: '/goods/memberprice/save',
    method: 'post',
    data: query
  })
}

export function updateItemRebateConf (query) {
  return fetch({
    url: '/goods/rebateconf',
    method: 'post',
    data: query
  })
}

export function saveTag (query) {
  return fetch({
    url: '/goods/tag',
    method: 'post',
    data: query
  })
}

export function getTagList (query) {
  return fetch({
    url: '/goods/tag',
    method: 'get',
    params: query
  })
}

export function getTagInfo (tagId) {
  return fetch({
    url: '/goods/tag/' + tagId,
    method: 'get'
  })
}

export function updateTag (query) {
  return fetch({
    url: '/goods/tag',
    method: 'put',
    data: query
  })
}

export function deleteTag (tagId) {
  return fetch({
    url: '/goods/tag/' + tagId,
    method: 'delete'
  })
}

export function itemsRelTags (query) {
  return fetch({
    url: '/goods/reltag',
    method: 'post',
    data: query
  })
}

export function tagSearchItem (query) {
  return fetch({
    url: '/goods/tagsearch',
    method: 'GET',
    params: query
  })
}

export function setHotKeywords (query) {
  return fetch({
    url: '/goods/hotkeywords',
    method: 'post',
    data: query
  })
}

export function getHotKeywords () {
  return fetch({
    url: '/goods/hotkeywords',
    method: 'get'
  })
}

export function updateItemsStore (query) {
  return fetch({
    url: '/goods/itemstoreupdate',
    method: 'put',
    data: query
  })
}

export function updateItemsStatus (query) {
  return fetch({
    url: '/goods/itemstatusupdate',
    method: 'put',
    data: query
  })
}

export function updateItemsAuthReceiving (query) {
  return fetch({
    url: '/goods/itemreceivingupdate',
    method: 'put',
    data: query
  })
}

export function auditItems (query) {
  return fetch({
    url: '/goods/audit/items',
    method: 'put',
    data: query
  })
}

export function getGoodsExport (query) {
  return fetch({
    url: '/goods/export',
    method: 'get',
    params: query
  })
}

export function exportItemsData (data) {
  return fetch({
    url: '/goods/export',
    method: 'post',
    data
  })
}

export function exportRecommendItemsData (data) {
  return fetch({
    url: '/goods/export_recommend',
    method: 'post',
    data
  })
}

export function syncItems () {
  return fetch({
    url: '/goods/sync/items',
    method: 'post'
  })
}

export function syncMainCategory () {
  return fetch({
    url: '/goods/sync/itemCategory',
    method: 'post'
  })
}

export function syncItemSpec () {
  return fetch({
    url: '/goods/sync/itemSpec',
    method: 'post'
  })
}

export function syncBrand () {
  return fetch({
    url: '/goods/sync/brand',
    method: 'post'
  })
}

export function saveIsGifts (query) {
  return fetch({
    url: '/goods/itemsisgiftupdate',
    method: 'put',
    data: query
  })
}

export function itemSyncToDistributor (data) {
  return fetch({
    url: '/goods/sync/distributor',
    method: 'post',
    data
  })
}

export function getItemSyncDistributorLog (query) {
  return fetch({
    url: '/goods/sync/distributor/log',
    method: 'get',
    params: query
  })
}

export function exportSyncDistributorLog (query) {
  return fetch({
    url: '/goods/sync/distributor/exportSyncDistributorLog',
    method: 'get',
    params: query
  })
}

export function getAttributeInfo (query) {
  return fetch({
    url: '/goods/attribute/info',
    method: 'get',
    params: query
  })
}

export function getGoodsPreviewQrCode (query) {
  return fetch({
    url: '/goods/preview',
    method: 'get',
    params: query
  })
}

export function updateEduItemsParam (data) {
  return fetch({
    url: '/goods/items/eduparams',
    method: 'post',
    data
  })
}

export function getPedddingItemsList (query) {
  return fetch({
    url: '/goods/append/items',
    method: 'get',
    params: query
  })
}
export function savePedddingItemsList (data) {
  return fetch({
    url: '/goods/append/items',
    method: 'post',
    data
  })
}
export function delPedddingItemsList (query) {
  return fetch({
    url: '/goods/append/items',
    method: 'delete',
    params: query
  })
}

export function seriesInfo (query) {
  return fetch({
    url: '/goods/series/info',
    method: 'get',
    params: query
  })
}

export function seriesList (query) {
  return fetch({
    url: '/goods/series/list',
    method: 'get',
    params: query
  })
}

export function seriesCreate (data) {
  return fetch({
    url: '/goods/series/create',
    method: 'post',
    data
  })
}

export function seriesUpdate (seriesId, data) {
  return fetch({
    url: '/goods/series/update/' + seriesId,
    method: 'post',
    data: data
  })
}

export function getDeliverySettingList (query) {
  return fetch({
    url: '/delivery/setting/list',
    method: 'get',
    params: query
  })
}

export function getNotCartList (query) {
  return fetch({
    url: '/delivery/notcartitems/list',
    method: 'get',
    params: query
  })
}
export function delNotCartGoods (query) {
  return fetch({
    url: '/delivery/notcartitems/del',
    method: 'delete',
    params: query
  })
}

export function addNotCartGoods (query) {
  return fetch({
    url: '/delivery/notcartitems/add',
    method: 'post',
    params: query
  })
}


export function delDeliverySettingGoods (query) {
  return fetch({
    url: '/delivery/setting/del',
    method: 'delete',
    params: query
  })
}

export function addDeliverySettingGoods (query) {
  return fetch({
    url: '/delivery/setting/add',
    method: 'post',
    params: query
  })
}
export function updateDeliverySettingValues (query) {
  return fetch({
    url: '/delivery/setting/update',
    method: 'put',
    data: query
  })
}
export function updateDeliverySettingDefaultValues (query) {
  return fetch({
    url: '/delivery/setting/default/update',
    method: 'put',
    data: query
  })
}

export function itemIsActivePage (query) {
  return fetch({
    url: '/goods/itemIsActivePage',
    method: 'post',
    data: query
  })
}

// 获取推荐商品列表
export function recommendGoodsLists (query) {
  return fetch({
    url: '/promotions/order/recommend/goods/lists',
    method: 'get',
    params: query
  })
}

// 添加推荐商品
export function recommendGoodsCreate (data) {
  return fetch({
    url: '/promotions/order/recommend/goods/create',
    method: 'post',
    data
  })
}

// 修改订单推荐商品状态和排序
export function recommendGoodsUpdateStatus (data) {
  return fetch({
    url: '/promotions/order/recommend/goods/update/status',
    method: 'post',
    data
  })
}

// 修改订单推荐商品关联商品
export function recommendGoodsUpdateRel (data) {
  return fetch({
    url: '/promotions/order/recommend/goods/update/rel',
    method: 'post',
    data
  })
}

// 删除订单推荐商品
export function recommendGoodsDelete (data) {
  return fetch({
    url: '/promotions/order/recommend/goods/delete',
    method: 'post',
    data
  })
}

// 获取订单推荐商品关联商品
export function recommendGoodsRelList (query) {
  return fetch({
    url: '/promotions/order/recommend/goods/rel/list',
    method: 'get',
    params: query
  })
}

// 删除订单推荐商品关联商品
export function recommendGoodsRelDelete (data) {
  return fetch({
    url: '/promotions/order/recommend/goods/rel/delete',
    method: 'post',
    data
  })
}

// 修改商品属性
export function updateGoodsAttrNew (data) {
  return fetch({
    url: '/goods/attributes_new/update',
    method: 'post',
    data
  })
}

// 获取商品绑定的参数列表
export function getGoodsParams (query) {
  return fetch({
    url: '/goods/params',
    method: 'get',
    params: query
  })
}

// 绑定商品参数
export function setGoodsParams (data) {
  return fetch({
    url: '/goods/relparams',
    method: 'post',
    data
  })
}

// 更新商品属性排序
export function updateGoodsAttrSort (data) {
  return fetch({
    url: '/goods/attributes_new/update_sort',
    method: 'post',
    data
  })
}
