import fetch from '../utils/fetch'

export function handleUploadFile (query) {
  return fetch({
    url: '/espier/upload_file',
    method: 'post',
    data: query
  })
}

export function getUploadLists (query) {
  return fetch({
    url: '/espier/upload_files',
    method: 'get',
    params: query
  })
}

export function exportUploadErrorFile (id, query) {
  return fetch({
    url: '/espier/upload_error_file_export/' + id,
    method: 'get',
    params: query
  })
}

export function retryWaitUpload (id, query) {
  return fetch({
    url: '/espier/retry_upload_job/' + id,
    method: 'get',
    params: query
  })
}

export function exportUploadTemplate (query) {
  return fetch({
    url: '/espier/upload_template',
    method: 'get',
    params: query
  })
}

export function getBrandLogo () {
  return fetch({
    url: '/companys/setting',
    method: 'get'
  })
}

export function getAddress () {
  return fetch({
    url: '/espier/address',
    method: 'get'
  })
}

export function changePassWord (query) {
  return fetch({
    url: '/operator/pwd',
    method: 'put',
    data: query
  })
}
export function saveEcSetting (query) {
  return fetch({
    url: '/espier/ec/setting',
    method: 'post',
    data: query
  })
}
export function getEcSetting () {
  return fetch({
    url: '/espier/ec/get',
    method: 'get'
  })
}

export function getJoinCity () {
  return fetch({
    url: '/espier/ec/city',
    method: 'get'
  })
}
export function setJoinCity (query) {
  return fetch({
    url: '/espier/ec/city',
    method: 'post',
    data: query
  })
}

export function saveEcGoodsSettingGoods (query) {
  return fetch({
    url: '/espier/ec/goods',
    method: 'post',
    data: query
  })
}
export function getEcGoodsSettingList () {
  return fetch({
    url: '/espier/ec/goods',
    method: 'get'
  })
}
export function delEcGoodsSettingGoods (query) {
  return fetch({
    url: '/espier/ec/goods',
    method: 'delete',
    params: query
  })
}
export function setSortEcGoodsSettingGoods (query) {
  return fetch({
    url: '/espier/ec/goods/sort',
    method: 'post',
    data: query
  })
}
export function setDescEcGoodsSettingGoods (query) {
  return fetch({
    url: '/espier/ec/goods/desc',
    method: 'post',
    data: query
  })
}
export function setBindStatus (query) {
  return fetch({
    url: '/espier/ec/bind',
    method: 'post',
    data: query
  })
}
export function getBindInfo (query) {
  return fetch({
    url: '/espier/ec/bind',
    method: 'get',
    params: query
  })
}
export function saveTcSetting (post) {
  return fetch({
    url: '/espier/sf/save',
    method: 'post',
    data: post
  })
}
export function getTcSetting () {
  return fetch({
    url: '/espier/sf/list',
    method: 'get'
  })
}


