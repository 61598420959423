import fetch from '../utils/fetch'

// 获取活动详情
export function getBundleDiscountDetail (id) {
  return fetch({
    url: '/promotions/bundleDiscount/detail?activity_id=' + id,
    method: 'get'
  })
}

// 创建优惠搭售活动
export function addBundleDiscount (query = {}) {
  return fetch({
    url: '/promotions/bundleDiscount',
    method: 'post',
    data: query
  })
}

// 上传店铺
export function postDistributorUpload (query) {
  return fetch({
    url: '/promotions/bundleDiscount/tempDistributor',
    method: 'post',
    data: query
  })
}

// 获取活动店铺列表
export function getDiscountDistributorList (params = {}) {
  return fetch({
    url: '/promotions/bundleDiscount/distributors',
    method: 'get',
    params: params
  })
}

// 删除店铺暂存数据
export function deleteBundleDistributorList (params = {}) {
  return fetch({
    url: '/promotions/bundleDiscount/tempDistributor',
    method: 'delete',
    params: params
  })
}

// 上传优惠搭售商品
export function postDiscountGoodUpload (query = {}) {
  return fetch({
    url: '/promotions/bundleDiscount/tempItem',
    method: 'post',
    data: query
  })
}

// 获取搭售商品暂存数据列表
export function getDiscountGoodsList (params = {}) {
  return fetch({
    url: '/promotions/bundleDiscount/tempItem/list',
    method: 'get',
    params: params
  })
}

// 删除搭售商品暂存数据
export function deleteDiscountGoods (params = {}) {
  return fetch({
    url: '/promotions/bundleDiscount/tempItem',
    method: 'delete',
    params: params
  })
}

// 更新搭售商品信息
export function updateBundleDiscount (query) {
  return fetch({
    url: '/promotions/bundleDiscount/items',
    method: 'put',
    data: query
  })
}

// 修改活动排序
export function updateBundleDiscountSort (query) {
  return fetch({
    url: '/promotions/bundleDiscount/sort',
    method: 'put',
    data: query
  })
}

// 复制活动
export function copyBundleDiscount (query) {
  return fetch({
    url: '/promotions/bundleDiscount/copy',
    method: 'post',
    data: query
  })
}

// 中止活动
export function stopBundleDiscount (query) {
  return fetch({
    url: '/promotions/bundleDiscount/stop',
    method: 'put',
    data: query
  })
}

// 删除活动
export function deleteBundleDiscount (params) {
  return fetch({
    url: '/promotions/bundleDiscount',
    method: 'delete',
    params: params
  })
}
