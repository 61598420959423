import fetch from '../utils/fetch'

// 抽奖活动列表
export function getLotteryActivityList (params = {}) {
  return fetch({
    url: '/lottery/activity/list',
    method: 'get',
    params
  })
}

// 创建抽奖活动
export function addLotteryActivity (params = {}) {
  return fetch({
    url: '/lottery/activity/create',
    method: 'post',
    data: params
  })
}

// 抽奖活动详情
export function getLotteryActivityDetail (params = {}) {
  return fetch({
    url: '/lottery/activity/info',
    method: 'get',
    params
  })
}

// 修改抽奖活动
export function editLotteryActivity (params = {}) {
  return fetch({
    url: '/lottery/activity/update/' + params.marketing_id,
    method: 'post',
    data: params
  })
}

// 终止抽奖活动
export function stopLotteryActivity (params = {}) {
  return fetch({
    url: '/lottery/activity/close',
    method: 'post',
    data: params
  })
}

// 设置门店限额
export function setDistributorQuota (params = {}) {
  return fetch({
    url: '/lottery/distributor/quota',
    method: 'post',
    data: params
  })
}

// 常规礼品门店列表
export function getDistributorQuotaList (params = {}) {
  return fetch({
    url: '/lottery/distributor/quota/list',
    method: 'get',
    params
  })
}

// 常规礼品门店场次限额详情
export function getDistributorQuotaInfo (params = {}) {
  return fetch({
    url: '/lottery/distributor/quota/info',
    method: 'get',
    params
  })
}

// 活动中奖名单
export function getActivityWinningList (params = {}) {
  return fetch({
    url: '/lottery/activity/winning/list',
    method: 'get',
    params
  })
}

// 中奖通知
export function sendNoticeMsg (params = {}) {
  return fetch({
    url: '/lottery/activity/winning/giftinshop',
    method: 'post',
    data: params
  })
}

// 获取核销码中奖详情
export function getWinnerDetail (params = {}) {
  return fetch({
    url: '/lottery/activity/winning/verifivation/detail',
    method: 'get',
    params
  })
}

// 核销
export function activityVerify (params = {}) {
  return fetch({
    url: '/lottery/activity/winning/verifivation',
    method: 'post',
    data: params
  })
}

// 抽奖记录
export function getActivityRecordList (params = {}) {
  return fetch({
    url: '/lottery/activity/record/list',
    method: 'get',
    params
  })
}
