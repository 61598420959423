import fetch from '../utils/fetch'

export function getGatewaySettingList (query) {
  return fetch({
    url: '/gateway/setting/list',
    method: 'get',
    params: query
  })
}
export function setGatewaySetting (query) {
  return fetch({
    url: '/gateway/setting',
    method: 'post',
    data: query
  })
}
export function delGatewaySetting (query) {
  return fetch({
    url: '/gateway/setting',
    method: 'delete',
    params: query
  })
}
export function setIpadProtocolSetting (query) {
  return fetch({
    url: '/gateway/ipad/setting',
    method: 'post',
    data: query
  })
}
export function getIpadProtocolSetting (query) {
  return fetch({
    url: '/gateway/ipad/setting',
    method: 'get',
    params: query
  })
}

