import fetch from '../utils/fetch'

export function saveDistributor (query) {
  if (query.distributor_id) {
    return fetch({
      url: '/distributor/' + query.distributor_id,
      method: 'put',
      data: query
    })
  } else {
    return fetch({
      url: '/distributor',
      method: 'post',
      data: query
    })
  }
}

export function saveOpen (data) {
  return fetch({
    url: '/hfpay/enterapply/opensplit',
    method: 'post',
    data
  })
}
export function saveSFConfig (data) {
  return fetch({
    url: '/sfconfig/save',
    method: 'post',
    data
  })
}
export function getDistributorList (query) {
  return fetch({
    url: '/distributors',
    method: 'get',
    params: query
  })
}

export function getBasicConfig () {
  return fetch({
    url: '/distribution/basic_config',
    method: 'get'
  })
}

export function saveBasicConfig (query) {
  return fetch({
    url: '/distribution/basic_config',
    method: 'post',
    data: query
  })
}

export function getDistributeLogs (query) {
  return fetch({
    url: '/distribution/logs',
    method: 'get',
    params: query
  })
}

export function getCashWithdrawalPayInfo (cash_withdrawal_id) {
  return fetch({
    url: '/distributor/cash_withdrawal/payinfo/' + cash_withdrawal_id,
    method: 'get'
  })
}

export function getCashWithdrawals (query) {
  return fetch({
    url: '/distribution/cash_withdrawals',
    method: 'get',
    params: query
  })
}

export function processCashWithdrawal (id, query) {
  return fetch({
    url: '/distribution/cash_withdrawal/' + id,
    method: 'put',
    params: query
  })
}

export function getDistributorCount (distributorId) {
  return fetch({
    url: '/distributor/count/' + distributorId,
    method: 'get'
  })
}

export function getDistributionCount () {
  return fetch({
    url: '/distribution/count',
    method: 'get'
  })
}

export function saveDistributorItems (query) {
  return fetch({
    url: '/distributor/items',
    method: 'post',
    data: query
  })
}

export function getDistributorItems (query) {
  return fetch({
    url: '/distributor/items',
    method: 'get',
    params: query
  })
}

export function exportDistributorItems (query) {
  return fetch({
    url: '/distributor/items/export',
    method: 'get',
    params: query
  })
}

export function getWxaDristributorCodeStream (query) {
  return fetch({
    url: '/distributor/wxacode',
    method: 'get',
    params: query
  })
}

export function delDistributorItems (query) {
  return fetch({
    url: '/distributor/item',
    method: 'delete',
    params: query
  })
}

export function updateDistributorItem (query) {
  return fetch({
    url: '/distributors/item',
    method: 'put',
    params: query
  })
}

export function setDefaultDistributor (query) {
  return fetch({
    url: '/distributor/default',
    method: 'post',
    data: query
  })
}

export function getSalesmanList (query) {
  return fetch({
    url: '/distributor/salesmans',
    method: 'get',
    params: query
  })
}

export function updateSalesman (salesmanId, query) {
  return fetch({
    url: '/distributor/salesman/' + salesmanId,
    method: 'put',
    data: query
  })
}

export function getSalesmanRole (query) {
  return fetch({
    url: '/distributor/salesman/role',
    method: 'get',
    params: query
  })
}

export function updateSalesmanRole (salesmanId, query) {
  return fetch({
    url: '/distributor/salesman/role/' + salesmanId,
    method: 'put',
    data: query
  })
}

export function addSalesman (query) {
  return fetch({
    url: '/distributor/salesman',
    method: 'post',
    data: query
  })
}

export function getDistributorInfo (query) {
  return fetch({
    url: '/distributors/info',
    method: 'get',
    params: query
  })
}

export function saveTag (params) {
  return fetch({
    url: '/distributor/tag',
    method: 'post',
    data: params
  })
}

export function getTagList (query) {
  return fetch({
    url: '/distributor/tag',
    method: 'get',
    params: query
  })
}

export function getTagInfo (tagId) {
  return fetch({
    url: '/distributor/tag/' + tagId,
    method: 'get'
  })
}

export function updateTag (tagId, params) {
  return fetch({
    url: '/distributor/tag/' + tagId,
    method: 'put',
    data: params
  })
}

export function deleteTag (tagId) {
  return fetch({
    url: '/distributor/tag/' + tagId,
    method: 'delete'
  })
}

export function distributorRelTags (query) {
  return fetch({
    url: '/distributor/reltag/',
    method: 'post',
    data: query
  })
}

export function getDistributorEasyList (query) {
  return fetch({
    url: '/distributor/easylist',
    method: 'get',
    params: query
  })
}

export function addDistributorShop (query) {
  if (query.distributor_id) {
    return fetch({
      url: '/shops/' + query.distributor_id,
      method: 'put',
      data: query
    })
  } else {
    return fetch({
      url: '/shops',
      method: 'post',
      data: query
    })
  }
}
export function setDefaultDistributorShop (query) {
  return fetch({
    url: '/dshops/setDefaultShop',
    method: 'post',
    data: query
  })
}
export function setDistributorShopStatus (query) {
  return fetch({
    url: '/dshops/setShopStatus',
    method: 'post',
    data: query
  })
}
export function getDistributorShopList (query) {
  return fetch({
    url: '/shops',
    method: 'get',
    params: query
  })
}
export function getDistributorShopDetail (id, query) {
  return fetch({
    url: '/shops/' + id,
    method: 'get',
    params: query
  })
}
export function deleteDistributorShop (id) {
  return fetch({
    url: '/shops/' + id,
    method: 'delete'
  })
}
export function getSalemanCustomerComplaints (params) {
  return fetch({
    url: '/distributor/salemanCustomerComplaints',
    method: 'get',
    params
  })
}
export function replySalemanCustomerComplaints (data) {
  return fetch({
    url: '/distributor/salemanCustomerComplaints',
    method: 'post',
    data
  })
}

// 获取导购通知列表
export function getSalespersonoticeList (params) {
  return fetch({
    url: '/salespersonotice/list',
    method: 'get',
    params
  })
}

// 新增导购通知
export function setSalespersonoticeNotice (data) {
  return fetch({
    url: '/salespersonotice/notice',
    method: 'post',
    data
  })
}

// 撤回通知导购通知
export function delSalespersonoticeWithdrawnotice (data) {
  return fetch({
    url: '/salespersonotice/withdrawnotice',
    method: 'post',
    data
  })
}

// 获取导购通知详情
export function getSalespersonoticeDetail (params) {
  return fetch({
    url: '/salespersonotice/detail',
    method: 'get',
    params
  })
}

// 修改导购通知
export function editSalespersonoticeNotice (data) {
  return fetch({
    url: '/salespersonotice/notice',
    method: 'put',
    data
  })
}

// 发送导购通知
export function setSalespersonoticeSendnotice (data) {
  return fetch({
    url: 'salespersonotice/sendnotice',
    method: 'post',
    data
  })
}

// 获取门店角色列表
export function getRoleList (query) {
  return fetch({
    url: 'distributor/salesperson/role',
    method: 'get',
    params: query
  })
}

// 获取门店角色信息
export function getRoleInfo (id, query) {
  return fetch({
    url: 'distributor/salesperson/role/' + id,
    method: 'get',
    params: query
  })
}

// 创建门店角色信息
export function createRole (params) {
  return fetch({
    url: 'distributor/salesperson/role/',
    method: 'post',
    data: params
  })
}

// 修改门店角色信息
export function updateRole (id, params) {
  return fetch({
    url: 'distributor/salesperson/role/' + id,
    method: 'put',
    data: params
  })
}

// 删除门店角色信息
export function deleteRole (id, query) {
  return fetch({
    url: 'distributor/salesperson/role/' + id,
    method: 'delete'
  })
}

// 获取距离信息
export function getDistance () {
  return fetch({
    url: 'distribution/getdistance',
    method: 'get'
  })
}
// 设置距离信息
export function setDistance (params) {
  return fetch({
    url: 'distribution/setdistance',
    method: 'post',
    data: params
  })
}
// 新增修改经销商
export function saveDealer (query) {
  if (query.dealer_id) {
    return fetch({
      url: '/dealer/' + query.dealer_id,
      method: 'put',
      data: query
    })
  } else {
    return fetch({
      url: '/dealer',
      method: 'post',
      data: query
    })
  }
}

export function openDealer (query) {
  return fetch({
    url: '/opendealer/' + query.dealer_id,
    method: 'put',
    data: query
  })
}
export function getDealerInfo (query) {
  return fetch({
    url: '/dealer/info',
    method: 'get',
    params: query
  })
}
export function getDealerList (query) {
  return fetch({
    url: '/dealer',
    method: 'get',
    params: query
  })
}
export function getDealerRtmList (query) {
  return fetch({
    url: '/dealer_rtm',
    method: 'get',
    params: query
  })
}

export function getDistributorRelPreferential (query) {
  return fetch({
    url: '/preferential/rel/distributor',
    method: 'get',
    params: query
  })
}

export function setDistributorRelPreferential (query) {
  return fetch({
    url: '/preferential/rel/distributor',
    method: 'put',
    data: query
  })
}

export function adapayMemberBind (query) {
  return fetch({
    url: '/distributor/adapayMember/bind',
    method: 'put',
    params: query
  })
}

export function getExamineDistributorList (query) {
  return fetch({
    url: '/examine/distributor/list',
    method: 'get',
    params: query
  })
}

export function submitExamineDistributor (query) {
  return fetch({
    url: '/subexamine/distributor',
    method: 'post',
    data: query
  })
}

export function examineDistributor (query) {
  return fetch({
    url: '/examine/distributor',
    method: 'post',
    data: query
  })
}

export function getEduConfig (params) {
  return fetch({
    url: '/distributor/edu/config',
    method: 'get',
    params: params
  })
}

export function saveEduConfig (params) {
  return fetch({
    url: '/distributor/edu/config',
    method: 'post',
    data: params
  })
}

export function getRedpacketList (params) {
  return fetch({
    url: '/redpacketcover/list',
    method: 'GET',
    params
  })
}

// 添加红包封面
export function addRedCover (params) {
  return fetch({
    url: '/redpacketcover/set',
    method: 'post',
    data: params
  })
}

// 门店活动
export function getOfflineactivityList (params) {
  return fetch({
    url: '/offlineactivity/list',
    method: 'GET',
    params
  })
}

export function createTemporaryDeliveryPlace (params) {
  return fetch({
    url: '/temporaryDeliveryPlace',
    method: 'post',
    data: params
  })
}

export function updateTemporaryDeliveryPlace (params) {
  return fetch({
    url: '/temporaryDeliveryPlace',
    method: 'put',
    params: params
  })
}

export function getTemporaryDeliveryPlaceList (params) {
  return fetch({
    url: '/temporaryDeliveryPlace/list',
    method: 'get',
    params: params
  })
}

export function getTemporaryDeliveryPlaceDetail (params) {
  return fetch({
    url: '/temporaryDeliveryPlace/detail',
    method: 'get',
    params: params
  })
}

// 获取导购登录记录
export function getDistributorEduSecurity (query) {
  return fetch({
    url: '/distributor/edu/secret',
    method: 'get',
    params: query
  })
}

// 获取服务评价设置
export function getEvaluationConfig () {
  return fetch({
    url: '/evaluation/config',
    method: 'get',
  })
}

// 保存服务评价设置
export function postEvaluationConfig (params) {
  return fetch({
    url: '/evaluation/config',
    method: 'post',
    params: params
  })
}

// 新增评价标签
export function postEvaluationTag (params = {}) {
  return fetch({
    url: '/evaluation/tag',
    method: 'post',
    params: params
  })
}

// 获取评价标签列表
export function getEvaluationTagList (params = {}) {
  return fetch({
    url: '/evaluation/tag/list',
    method: 'get',
    params: params
  })
}

// 获取评价标签详情
export function getEvaluationTagDetail (params = {}) {
  return fetch({
    url: '/evaluation/tag/detail',
    method: 'get',
    params: params
  })
}

// 删除评价标签
export function deleteEvaluationTag (params = {}) {
  return fetch({
    url: '/evaluation/tag',
    method: 'delete',
    params: params
  })
}

// 修改评价标签
export function putEvaluationTag (params = {}) {
  return fetch({
    url: '/evaluation/tag',
    method: 'put',
    params: params
  })
}

// 查看店铺评价详情
export function getDistributorEvaluationList (params = {}) {
  return fetch({
    url: '/distributor/evaluation/list',
    method: 'get',
    params: params
  })
}

// 订单评价添加店员备注
export function postEvaluationFinishcontact (params = {}) {
  return fetch({
    url: '/distributor/evaluation/finishcontact',
    method: 'post',
    params: params
  })
}
