import fetch from '../utils/fetch'

export function getAfterSaleMaterialList(query) {
  return fetch({
    url: '/aftersales/material/list',
    method: 'get',
    params: query
  })
}

export function addRealMaterialData(data) {
  return fetch({
    url: '/aftersales/material/real/add',
    method: 'post',
    data: data
  })
}

export function getRealAfterMaterialList(query) {
  return fetch({
    url: '/aftersales/material/real/getlist',
    method: 'get',
    params: query
  })
}

export function getRealAfterMaterialDetail(query) {
  return fetch({
    url: '/aftersales/material/real/getdetail',
    method: 'get',
    params: query
  })
}

export function editRealAfterMaterial(id, data) {
  data.id = id
  return fetch({
    url: '/aftersales/material/real/edit',
    method: 'post',
    data: data
  })
}

export function editRealAfterMaterialList(id, data) {
  data.id = id
  return fetch({
    url: '/aftersales/material/real/editList',
    method: 'post',
    data: data
  })
}

export function updateSyncStatus(data) {
  return fetch({
    url: '/aftersales/material/sync/status/edit',
    method: 'post',
    data: data
  })
}

export function getSyncStatus() {
  return fetch({
    url: '/aftersales/material/sync/status',
    method: 'post',
    data: {}
  })
}
// export function getRegisterPromotions(query) {
//   return fetch({
//     url: '/promotions/register',
//     method: 'get',
//     params: query
//   })
// }
