import fetch from '@/utils/fetch'

export function getImageList(params) {
  return fetch({
    url: '/espier/images',
    method: 'get',
    params
  })
}

// 添加图片分组
export function addImageCatgory(params) {
  return fetch({
    url: '/espier/image/group',
    method: 'post',
    params
  })
}

// 获取图片分组
export function getImageAllCatgory(params) {
  return fetch({
    url: `/espier/image/group/list`,
    method: 'get',
    params
  })
}

// 删除图片分组
export function deleteImageGroup(group_id) {
  return fetch({
    url: `/espier/image/group`,
    method: 'delete',
    params: {
      group_id
    }
  })
}

// 移动图片分组
export function moveImageGroup(params) {
  return fetch({
    url: '/espier/image/group/move',
    method: 'post',
    params
  })
}
