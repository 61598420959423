import { importAll } from '@/utils'
import req, { createAxios } from '@/utils/fetch'

import * as aftersales from './aftersales'
import * as article from './article'
import * as bytemini from './bytemini'
import * as cardticket from './cardticket'
import * as common from './common'
import * as community from './community'
import * as company from './company'
import * as datacube from './datacube'
import * as deposit from './deposit'
import * as fans from './fans'
import * as goods from './goods'
import * as im from './im'
import * as login from './login'
import * as logistics from './logistics'
import * as marketing from './marketing'
import * as member from './member'
import * as membercard from './membercard'
import * as menu from './menu'
import * as onecode from './onecode'
import * as pcgoods from './pcgoods'
import * as pctemplate from './pctemplate'
import * as point from './point'
import * as printer from './printer'
import * as promotions from './promotions'
import * as qiniu from './qiniu'
import * as reservation from './reservation'
import * as selfhelpform from './selfhelpform'
import * as shipping from './shipping'
import * as shop from './shop'
import * as template from './template'
import * as third from './third'
import * as trade from './trade'
import * as wechat from './wechat'
import * as wxa from './wxa'
import * as wxastats from './wxastats'
import * as addressBook from './addressBook'
import * as webtemplate from './webtemplate'
import * as coupon from './coupon'
import * as lottery from './lottery'
import * as abtest from './abtest'
import * as material from './material'
import * as piiInfo from './piiInfo'
import * as discount from './discount'

import * as ossStorage from './ossStorage'

const api = {}

const callbackWrap = (fn) => {
  const _fn = async (...arg) => {
    // console.log(arguments.length, ...arg)
    const res = await fn(...arg)
    console.log('callbackWrap', res)
    return res.data.data
  }
  return _fn
}
importAll(require.context('./', false, /\.js$/), (key, r) => {
  const keyPath = key.match(/\.\/(.+)\.js$/)[1]
  if (!['index'].includes(keyPath)) {
    const fn = {}
    Object.keys(r(key)).forEach((n) => {
      fn[n] = callbackWrap(r(key)[n])
    })
    api[keyPath] = fn
  }
})

function install(Vue) {
  Vue.prototype.$api = api
}

export {
  install
}

export default {
  coupon,
  aftersales,
  article,
  bytemini,
  cardticket,
  common,
  community,
  company,
  datacube,
  deposit,
  fans,
  goods,
  im,
  login,
  logistics,
  marketing,
  member,
  membercard,
  menu,
  onecode,
  pcgoods,
  pctemplate,
  point,
  printer,
  promotions,
  qiniu,
  reservation,
  selfhelpform,
  shipping,
  shop,
  template,
  third,
  trade,
  wechat,
  wxa,
  wxastats,
  ossStorage,
  addressBook,
  webtemplate,
  abtest,
  lottery,
  discount,
  material,
  piiInfo
}


