/*
 * @Author: Arvin
 * @GitHub: https://github.com/973749104
 * @Blog: https://liuhgxu.com
 * @Description: 说明
 * @FilePath: /app/src/api/addressBook.js
 * @Date: 2020-06-05 13:50:55
 * @LastEditors: Arvin
 * @LastEditTime: 2020-06-08 15:05:29
 */

import fetch from '../utils/fetch'

// 获取部门列表
export function getWorkwechat (params = {}) {
  return fetch({
    url: '/workwechat/report',
    method: 'get',
    data: params
  })
}

// 获取部门成员
export function getMemberByClub (departmentId) {
  return fetch({
    url: `/workwechat/report/${departmentId}`,
    method: 'get'
  })
}

// 同步部门信息到店铺
export function syncClubToStore (data) {
  return fetch({
    url: '/workwechat/report/syncDistributor',
    method: 'post',
    data
  })
}

// 同步成员到导购员
export function syncMemberToGuide (data) {
  return fetch({
    url: '/workwechat/report/syncSalesperson',
    method: 'post',
    data
  })
}
