import fetch from '../utils/fetch'

export function getCouponList (query) {
  return fetch({
    url: '/discountcard/consumecards/get',
    method: 'get',
    params: query
  })
}

export function postCouponCode (data) {
  console.log('data', data)
  return fetch({
    url: '/discountcard/consumecard',
    method: 'post',
    data
  })
}
export function getBindLists (query) {
  return fetch({
    url: '/discountcard/platform/list',
    method: 'get',
    params: query
  })
}
export function saveBind (data) {
  console.log('data', data)
  return fetch({
    url: '/discountcard/platform/save',
    method: 'post',
    data
  })
}
export function delBind (query) {
  return fetch({
    url: '/discountcard/platform/del',
    method: 'delete',
    params: query
  })
}
