import fetch from '../utils/fetch'

export function getSourcesList (query) {
  return fetch({
    url: '/datacube/sources',
    method: 'get',
    params: query
  })
}

export function getSourcesDetail (sourceId) {
  return fetch({
    url: '/datacube/sources/' + sourceId,
    method: 'get'
  })
}

export function createSources (query) {
  return fetch({
    url: '/datacube/sources',
    method: 'post',
    data: query
  })
}

export function updateSources (sourceId, query) {
  return fetch({
    url: '/datacube/sources/' + sourceId,
    method: 'put',
    data: query
  })
}

export function deleteSources (sourceId) {
  return fetch({
    url: '/datacube/sources/' + sourceId,
    method: 'delete'
  })
}

export function listMonitors (query) {
  return fetch({
    url: '/datacube/monitors',
    method: 'get',
    params: query
  })
}

export function addMonitors (query) {
  return fetch({
    url: '/datacube/monitors',
    method: 'post',
    data: query
  })
}

export function updateMonitors (monitorId, query) {
  return fetch({
    url: '/datacube/monitors/' + monitorId,
    method: 'put',
    data: query
  })
}

export function deleteMonitors (monitorId) {
  return fetch({
    url: '/datacube/monitors/' + monitorId,
    method: 'delete'
  })
}

export function getMonitorsWxaCode64 (query) {
  return fetch({
    url: '/datacube/monitorsWxaCode64',
    method: 'get',
    params: query
  })
}

export function getMonitorsWxaCodeStream (query) {
  return fetch({
    url: '/datacube/monitorsWxaCodeStream',
    method: 'get',
    params: query
  })
}

export function getMonitorsDetail (monitorId) {
  return fetch({
    url: '/datacube/monitors/' + monitorId,
    method: 'get'
  })
}

export function getMonitorsStats (query) {
  return fetch({
    url: '/datacube/monitorsstats',
    method: 'get',
    params: query
  })
}

export function saveSources (query) {
  return fetch({
    url: '/datacube/monitorsRelSources/',
    method: 'post',
    data: query
  })
}

export function getRelSources (monitorId) {
  return fetch({
    url: '/datacube/monitorsRelSources/' + monitorId,
    method: 'get'
  })
}

export function deleteRelSources (monitorId, sourceId) {
  return fetch({
    url: '/datacube/monitorsRelSources/' + monitorId + '/' + sourceId,
    method: 'delete'
  })
}

export function getWxappPages (query) {
  return fetch({
    url: '/datacube/miniprogram/pages',
    method: 'get',
    params: query
  })
}

export function getCompanyData (query) {
  return fetch({
    url: '/datacube/companydata',
    method: 'get',
    params: query
  })
}

export function getDistributorData (query) {
  return fetch({
    url: '/datacube/distributordata',
    method: 'get',
    params: query
  })
}

export function getGoodsData (query) {
  return fetch({
    url: '/datacube/goodsdata',
    method: 'get',
    params: query
  })
}

export function getorderdatapos (query) {
  return fetch({
    url: '/datacube/orderdatapos',
    method: 'get',
    params: query
  })
}
export function getorderdatahq (query) {
  return fetch({
    url: '/datacube/orderdatahq',
    method: 'get',
    params: query
  })
}

export function saveTags(query) {
  return fetch({
    url: '/datacube/savetags',
    method: 'post',
    data: query
  })
}

export function getResourceStatisticsData (query) {
  return fetch({
    url: '/datacube/statistics',
    method: 'get',
    params: query
  })
}

export function getQuestionnaireStatisticsList (query) {
  return fetch({
    url: '/datacube/questionnaire',
    method: 'get',
    params: query
  })
}

export function exportQuestionnaireDetail(query) {
  return fetch({
    url: '/datacube/questionnaire/detailexport',
    method: 'post',
    data: query
  })
}
