import fetch from '../utils/fetch'

// 获取配置信息
export function getSettingByLzSyncWorkWechatExternalUser () {
  return fetch({
    url: '/espier/setting/lz_sync_external',
    method: 'get'
  })
}

// 设置配置信息
export function setSettingByLzSyncWorkWechatExternalUser (params) {
  return fetch({
    url: '/espier/setting/lz_sync_external',
    method: 'post',
    data: params
  })
}

export function getCartSetting (query) {
  return fetch({
    url: '/getCartSetting',
    method: 'get',
    params: query
  })
}

export function setCartSetting (query) {
  return fetch({
    url: '/setCartSetting',
    method: 'post',
    data: query
  })
}

