import fetch from '../utils/fetch'

// 获取店铺场次列
export function getDistributorSessions () {
  return fetch({
    url: '/aftersales/repair/distributorSessions',
    method: 'get'
  })
}
// 保存店铺场次
export function saveDistributorSessions (data) {
  return fetch({
    url: '/aftersales/repair/distributorSessions',
    data,
    method: 'POST'
  })
}

// 修改店铺场次状态
export function changeDistributorSessionsStatus (data) {
  return fetch({
    url: '/aftersales/repair/distributorSessions/status',
    data,
    method: 'PUT'
  })
}

// 删除店铺场次
export function delDistributorSessions (id) {
  return fetch({
    url: `/aftersales/repair/distributorSessions?session_id=${id}`,
    method: 'DELETE'
  })
}

// 获取所有维修产品-维修内容
export function getProductcontent () {
  return fetch({
    url: '/aftersales/repair/productcontent',
    method: 'get'
  })
}

//  获取店铺绑定的维修产品维修内容
export function getDistributorProductsContents (id) {
  return fetch({
    url: `/aftersales/repair/distributorProductsContents?distributor_id=${id}`,
    method: 'get'
  })
}
// /aftersales/repair/distributorProductContent     POST    保存店铺维修产品维修内容
export function saveDistributorProductContent (data) {
  return fetch({
    url: `/aftersales/repair/distributorProductContent`,
    data,
    method: 'POST'
  })
}

//   POST    更改店铺维修产品维修内容是否可现场维修
export function savefieldRepairable (data) {
  return fetch({
    url: `/aftersales/repair/distributorProductsContents/fieldRepairable`,
    data,
    method: 'PUT'
  })
}

// 获取店铺场次详情
export function getDistributorSessionsDetail (data) {
  return fetch({
    url: `/aftersales/repair/distributorSessions/detail`,
    params: data,
    method: 'get'
  })
}
