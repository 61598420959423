import fetch from '../utils/fetch'

export function getWxaAuthList () {
  return fetch({
    url: '/wxa/authorizer',
    method: 'get'
  })
}

export function getWxa (wid) {
  return fetch({
    url: '/wxa/' + wid,
    method: 'get'
  })
}

export function submitWxa (query) {
  return fetch({
    url: '/wxa',
    method: 'post',
    data: query
  })
}

export function getCodeUnlimit (query) {
  return fetch({
    url: '/wxa/codeunlimit',
    method: 'get',
    params: query
  })
}

export function getTestQrcode (query) {
  return fetch({
    url: '/wxa/testqrcode',
    method: 'get',
    params: query
  })
}

export function tryRelease (query) {
  return fetch({
    url: '/wxa/tryrelease',
    method: 'post',
    data: query
  })
}

export function setPageParams (query) {
  return fetch({
    url: '/wxa/pageparams/setting',
    method: 'post',
    data: query
  })
}

export function getParamByTempName (query) {
  return fetch({
    url: '/wxa/pageparams/setting',
    method: 'get',
    params: query
  })
}

export function undocodeaudit (query) {
  return fetch({
    url: '/wxa/undocodeaudit',
    method: 'get',
    params: query
  })
}

export function revertcoderelease (query) {
  return fetch({
    url: '/wxa/revertcoderelease',
    method: 'get',
    params: query
  })
}

export function updateParamsById (query) {
  return fetch({
    url: '/wxa/pageparams/setting',
    method: 'put',
    data: query
  })
}

export function savePageParams (query) {
  return fetch({
    url: '/wxa/pageparams/setting_all',
    method: 'post',
    data: query
  })
}

export function getWxaMessageTemplateList (query) {
  return fetch({
    url: '/wxa/notice/templates',
    method: 'get',
    params: query
  })
}
export function getCustomPageList (query) {
  return fetch({
    url: '/wxa/customizepage/list',
    method: 'get',
    params: query
  })
}

export function openWxaMessageTemplate (query) {
  return fetch({
    url: '/wxa/notice/templates',
    method: 'put',
    data: query
  })
}

export function createCustomPage (query) {
  return fetch({
    url: '/wxa/customizepage',
    method: 'post',
    data: query
  })
}

export function editCustomPage (id, query) {
  return fetch({
    url: `/wxa/customizepage/${id}`,
    method: 'put',
    data: query
  })
}

export function delCustomPage (id, query) {
  return fetch({
    url: `/wxa/customizepage/${id}`,
    method: 'delete',
    data: query
  })
}
// 修改小程序配置信息
export function configSubmitHandle (wid, query) {
  return fetch({
    url: '/wxa/config/' + wid,
    method: 'put',
    data: query
  })
}
// 获取小程序配置
export function getWxaConfig (wid) {
  return fetch({
    url: '/wxa/config/' + wid,
    method: 'get'
  })
}

// 添加直连小程序
export function addWxapp (query) {
  return fetch({
    url: '/wechat/directbind',
    method: 'post',
    data: query
  })
}

export function submitOnlyCode (query) {
  return fetch({
    url: '/wxa/onlycode',
    method: 'post',
    data: query
  })
}

export function submitReview (query) {
  return fetch({
    url: '/wxa/submitreview',
    method: 'post',
    data: query
  })
}

export function getDomainList (query) {
  return fetch({
    url: '/wxa/getdomainlist',
    method: 'post',
    data: query
  })
}
export function saveDomain (query) {
  return fetch({
    url: '/wxa/savedomain',
    method: 'post',
    data: query
  })
}

// 保存小程序模板
export function saveTemplate (query) {
  return fetch({
    url: '/wxappTemplate/wxapp',
    method: 'put',
    data: query
  })
}

// 获取相关域名
export function getdomain () {
  return fetch({
    url: '/wxappTemplate/domain',
    method: 'get'
  })
}

// 设置相关域名
export function setdomain (query) {
  return fetch({
    url: '/wxappTemplate/domain',
    method: 'put',
    data: query
  })
}

// 获取问卷模板列表
export function getQuestionnairetplList (query) {
  return fetch({
    url: '/wxa/questionnairetpl/list',
    method: 'get',
    params: query
  })
}

export function createQuestionnairetpl (query) {
  return fetch({
    url: '/wxa/questionnairetpl',
    method: 'post',
    data: query
  })
}

export function updateQuestionnairetpl (query) {
  return fetch({
    url: 'wxa/questionnairetpl/update',
    method: 'post',
    data: query
  })
}

export function getQuestionnairetplDetail (id) {
  return fetch({
    url: `/wxa/questionnairetpl/${id}`,
    method: 'get'
  })
}

export function delQuestionnairetpl (id) {
  return fetch({
    url: `/wxa/questionnairetpl/${id}`,
    method: 'delete'
  })
}


export function getCustomPageAgreementList (query) {
  return fetch({
    url: '/wxa/customizepageagreement/list',
    method: 'get',
    params: query
  })
}

export function getQuestionnaireSubmitList(query) {
  return fetch({
    url: `/wxa/questionnaire/list`,
    method: 'get',
    params: query
  })
}
export function setStoreQrcodeSetting (query) {
  return fetch({
    url: '/wxa/setStoreQrcodeSetting',
    method: 'post',
    data: query
  })
}

export function getStoreQrcodeSetting (query) {
  return fetch({
    url: '/wxa/getStoreQrcodeSetting',
    method: 'get',
    params: query
  })
}

export function setQuestionSort (data) {
  return fetch({
    url: `/wxa/questionnairetpl/questionSort`,
    method: 'put',
    data
  })
}


export function delQuestionOption (query) {
  return fetch({
    url: `/wxa/questionnairetpl/question`,
    method: 'delete',
    params: query
  })
}


export function getQuestionnaireLevelQuestionList (query) {
  return fetch({
    url: `/wxa/questionnairetpl/levelQuestionList`,
    method: 'get',
    params: query
  })
}

export function getSumbitGroupList (query) {
  return fetch({
    url: `/wxa/questionnaire/submit/group/list`,
    method: 'get',
    params: query
  })
}

export function getQuestionnaireSubmitGroupList (query) {
  return fetch({
    url: `/wxa/questionnaire/submit/levelGroup/list`,
    method: 'get',
    params: query
  })
}
