import fetch from '../utils/fetch'

export function login (query) {
  return fetch({
    url: '/operator/login',
    method: 'post',
    data: query
  })
}

export function getSmsCode (params) {
  return fetch({
    url: '/operator/sms/code',
    method: 'post',
    data: params
  })
}

export function getImageCode () {
  return fetch({
    url: '/operator/images/code',
    method: 'get'
  })
}

export function resetPassword (params) {
  return fetch({
    url: '/operator/resetpassword',
    method: 'post',
    data: params
  })
}

export function getAdminInfo() {
  return fetch({
    url: '/operator/getinfo',
    method: 'get'
  })
}

export function updateAdminInfo(query) {
  return fetch({
    url: '/operator/updatedata',
    method: 'put',
    data: query
  })
}

export function getAuthorizeUrl(params) {
  return fetch({
    url: '/operator/authorizeurl',
    method: 'get',
    params
  })
}
export function getAuthorizeLogin(data) {
  return fetch({
    url: '/operator/oauth/login',
    method: 'post',
    data
  })
}

export function getAuthorizelogout(params) {
  return fetch({
    url: '/operator/oauth/logout',
    method: 'get',
    params
  })
}

export function getLoginVerifyCode(params) {
  return fetch({
    url: '/operator/login/verify_code',
    method: 'post',
    data: params
  })
}

export function getNewOrderNoticeSetting() {
  return fetch({
    url: '/operator/newordernoticesetting',
    method: 'get',
  })
}

export function updateNewOrderNoticeSetting(params) {
  return fetch({
    url: '/operator/newordernoticesetting',
    method: 'post',
    data: params
  })
}

export function updateAccountStatus(query) {
  return fetch({
    url: '/account/status',
    method: 'put',
    data: query
  })
}


export function getMaoKey(){
  return fetch({
    url: '/qqmapkey/setting',
    method: 'get',
  })
}


export function invalidateToken(){
  return fetch({
    url: '/token/invalidate',
    method: 'get',
  })
}