import fetch from '../utils/fetch'

export function getExperimentList(params){
  return fetch({
    url: '/pagestemplate/getAbtestSensorList',
    method: 'get',
    params: params
  })
}
export function getExperimentDetail(params){
  return fetch({
    url: '/pagestemplate/getAbtestSensorDetail',
    method: 'get',
    params: params
  })
}