import fetch from '../utils/fetch'

export function getAftersalesList (query) {
  return fetch({
    url: '/aftersales',
    method: 'get',
    params: query
  })
}

export function exportList (query) {
  return fetch({
    url: '/aftersales/logExport',
    method: 'get',
    params: query
  })
}

// 获取所有维修产品-维修内容
export function getRepairProductcontent (query) {
  return fetch({
    url: '/aftersales/repair/productcontent',
    method: 'get',
    params: query
  })
}

export function getAftersalesDetail (aftersalesBn) {
  return fetch({
    url: '/aftersales/' + aftersalesBn,
    method: 'get'
  })
}

export function reviewAftersales (query) {
  return fetch({
    url: '/aftersales/review',
    method: 'post',
    data: query
  })
}

export function refundCheck (query) {
  return fetch({
    url: '/aftersales/refundCheck',
    method: 'post',
    data: query
  })
}

export function sendConfirm (query) {
  return fetch({
    url: '/aftersales/sendConfirm',
    method: 'post',
    data: query
  })
}

export function confirmReceipt (query) {
  return fetch({
    url: '/aftersales/confirmReceipt',
    method: 'post',
    data: query
  })
}

export function getAftersalesAddressList (query) {
  return fetch({
    url: '/distributors/aftersalesaddress',
    method: 'get',
    params: query
  })
}

export function getRefundsList (query) {
  return fetch({
    url: '/refund',
    method: 'get',
    params: query
  })
}

export function getRefundsDetail (refundBn) {
  return fetch({
    url: '/refund/detail/' + refundBn,
    method: 'get'
  })
}

export function exportRefundList (query) {
  return fetch({
    url: '/refund/logExport',
    method: 'get',
    params: query
  })
}

export function createAftersalesAddress (data) {
  return fetch({
	  url: '/distributors/aftersalesaddress',
	  method: 'post',
	  data
  })
}

export function updateAftersalesAddress (data) {
  return fetch({
	  url: '/distributors/aftersalesaddress',
	  method: 'put',
	  data
  })
}

export function deleteAftersalesAddress (id) {
  return fetch({
	  url: '/distributors/aftersalesaddress/' + id,
	  method: 'delete'
  })
}

export function getInvoiceList (query) {
  return fetch({
    url: '/distributors/invoice',
    method: 'get',
    params: query
  })
}

export function getInvoiceInfo (id, query) {
  return fetch({
    url: '/distributors/invoice/' + id,
    method: 'get',
    params: query
  })
}

export function createInvoice (params) {
  return fetch({
    url: '/distributors/invoice',
    method: 'post',
    data: params
  })
}

export function updateInvoice (id, params) {
  return fetch({
    url: '/distributors/invoice/' + id,
    method: 'put',
    data: params
  })
}

export function deleteInvoice (id) {
  return fetch({
    url: '/distributors/invoice/' + id,
    method: 'delete'
  })
}
export function setDefaultAftersalesAddress (id) {
  return fetch({
    url: '/distributors/aftersalesaddress/' + id,
    method: 'post'
  })
}

export function exportAftersalesFinancialList (query) {
  return fetch({
    url: '/aftersales/financial/export',
    method: 'get',
    params: query
  })
}

export function getAftersalesRemind () {
  return fetch({
    url: '/aftersales/remind/detail',
    method: 'get'
  })
}

export function setAftersalesRemind (data) {
  return fetch({
    url: '/aftersales/remind',
    method: 'post',
    data
  })
}

export function updateAfterSalesStatus (data) {
  return fetch({
    url: '/aftersales/updateAfterSalesStatus',
    method: 'post',
    data
  })
}

export function getAftersalesSettingList (query) {
  return fetch({
    url: '/aftersales/setting/list',
    method: 'get',
    params: query
  })
}
export function updateInstructions (query) {
  return fetch({
    url: '/aftersales/setting/updateInstructions',
    method: 'put',
    params: query
  })
}

export function getAftersalesSettingStatus () {
  return fetch({
    url: '/aftersales/setting/status',
    method: 'get'
  })
}
export function changeSettingStatus (query) {
  return fetch({
    url: '/aftersales/setting/status',
    method: 'put',
    params: query

  })
}
export function delAftersalesSettingGoods (query) {
  return fetch({
    url: '/aftersales/setting/del',
    method: 'delete',
    params: query
  })
}
export function addAftersalesSettingGoods (query) {
  return fetch({
    url: '/aftersales/setting/add',
    method: 'post',
    params: query
  })
}

export function getRepairProductList (query) {
  return fetch({
    url: '/aftersales/repair/product/list',
    params: query,
    method: 'get'
  })
}

export function createRepairProduct (data) {
  return fetch({
    url: '/aftersales/repair/product',
    method: 'post',
    data
  })
}

export function updateRepairProduct (data) {
  return fetch({
    url: '/aftersales/repair/product',
    method: 'put',
    params: data
  })
}

export function deleteRepairProduct (data) {
  return fetch({
    url: '/aftersales/repair/product',
    method: 'delete',
    params: data
  })
}

export function getRepairContentList (query) {
  return fetch({
    url: '/aftersales/repair/content/list',
    params: query,
    method: 'get'
  })
}

export function createRepairContent (data) {
  return fetch({
    url: '/aftersales/repair/content',
    method: 'post',
    data
  })
}

export function updateRepairContent (data) {
  return fetch({
    url: '/aftersales/repair/content',
    method: 'put',
    params: data
  })
}

export function deleteRepairContent (data) {
  return fetch({
    url: '/aftersales/repair/content',
    method: 'delete',
    params: data
  })
}

export function getRepairProductInfo (query) {
  return fetch({
    url: '/aftersales/repair/product/info',
    params: query,
    method: 'get'
  })
}

export function saveRepairProductContent (data) {
  return fetch({
    url: '/aftersales/repair/productcontent',
    method: 'post',
    data
  })
}

export function getRepairList (query) {
  return fetch({
    url: '/aftersales/repair/list',
    params: query,
    method: 'get'
  })
}

// 维修单核销
export function repairSignIn (data) {
  return fetch({
    url: '/aftersales/repair/signIn',
    data,
    method: 'post'
  })
}

// 维修单完成诊断
export function repairDiagnostic (data) {
  return fetch({
    url: '/aftersales/repair/diagnostic',
    data,
    method: 'post'
  })
}

// 维修单通知取机
export function repairNotifyPickUp (data) {
  return fetch({
    url: '/aftersales/repair/notifyPickUp',
    data,
    method: 'post'
  })
}

// 维修单通知取机
export function repairFinish (data) {
  return fetch({
    url: '/aftersales/repair/finish',
    data,
    method: 'post'
  })
}
