import fetch from '../utils/fetch'

export function getConfig () {
  return fetch({
    url: '/stepcheck/setting',
    method: 'get'
  })
}

export function saveConfig (query) {
  return fetch({
    url: '/stepcheck/setting',
    method: 'post',
    data: query
  })
}