import fetch from '../utils/fetch'

// 设置系统设置   自闭环
export function setSelfHandleStatus (params = {}) {
  return fetch({
    url: '/selfhandle/setting',
    method: 'post',
    data: params
  })
}

// 获取系统设置   自闭环
export function getSelfHandleStatus (params) {
  return fetch({
    url: '/selfhandle/setting',
    method: 'get',
    params: params
  })
}


// 设置自闭环门店
export function setSelfHandleShops (params = {}) {
  return fetch({
    url: '/selfhandle/addShops',
    method: 'post',
    data: params
  })
}
// 移除自闭环门店
export function removeShop (params = {}) {
  return fetch({
    url: '/selfhandle/removeShop',
    method: 'delete',
    params: params
  })
}
// 获取自闭环处理日志
export function getSelfHandleLog (query) {
  return fetch({
    url: '/selfhandle/log',
    method: 'get',
    params: query
  })
}
// 获取自闭环处理日志
export function exportSHLog (params) {
  return fetch({
    url: '/selfhandle/log/export',
    method: 'post',
    data: params
  })
}

// 获取自闭环处理日志
export function getSelfHandleLogOption (query) {
  return fetch({
    url: '/selfhandle/option/list',
    method: 'get',
    params: query
  })
}
// 获取自闭环处理日志
export function getLogById (query) {
  return fetch({
    url: '/selfhandle/getLogById',
    method: 'get',
    params: query
  })
}


export function getOrderListSelf(query) {
  return fetch({
    url: '/selfhandle/orders/self',
    method: 'get',
    params: query
  })
}
export function checkZitiCode(params) {
  return fetch({
    url: '/selfhandle/orders/checkcode',
    method: 'post',
    data: params
  })
}

export function optionOrder(params) {
  return fetch({
    url: '/selfhandle/orders/optionOrder',
    method: 'post',
    data: params
  })
}
export function checkSn(params) {
  return fetch({
    url: '/selfhandle/orders/checkSn',
    method: 'post',
    data: params
  })
}

export function getSn(query) {
  return fetch({
    url: '/selfhandle/orders/getSn',
    method: 'get',
    params: query
  })
}
