import fetch from '../utils/fetch'

export function getPiInfo (query) {
  return fetch({
    url: '/getPiInfo',
    method: 'get',
    params: query
  })
}

export function getPiiOptionType (query) {
  return fetch({
    url: '/getPiiOptionType',
    method: 'get',
    params: query
  })
}

export function getPiLog (query) {
  return fetch({
    url: '/getPiLog',
    method: 'get',
    params: query
  })
}

export function getPiiSetting (query) {
  return fetch({
    url: '/getPiiSetting',
    method: 'get',
    params: query
  })
}

export function setPiiSetting (query) {
  return fetch({
    url: '/setPiiSetting',
    method: 'post',
    data: query
  })
}
