import fetch from '../utils/fetch'

export function getTemplateList () {
  return fetch({
    url: '/wxa/templates/openlist',
    method: 'get'
  })
}

export function templateList () {
  return fetch({
    url: '/wxa/templates/list',
    method: 'get'
  })
}

export function templateOpen (query) {
  return fetch({
    url: '/wxa/templates/open',
    method: 'post',
    data: query
  })
}

export function getWeappId () {
  return fetch({
    url: '/wxa/templates/weappid',
    method: 'get'
  })
}

// 新模板管理
export function setPagesTemplate (query) {
  return fetch({
    url: '/pagestemplate/set',
    method: 'post',
    data: query
  })
}

export function getPagesTemplateSetInfo (query) {
  return fetch({
    url: '/pagestemplate/setInfo',
    method: 'get',
    params: query
  })
}

export function getPagesTemplateList (query) {
  return fetch({
    url: '/pagestemplate/lists',
    method: 'get',
    params: query
  })
}

export function getPagesTemplateCityList (query) {
  return fetch({
    url: '/pagestemplate/cityInfo',
    method: 'get',
    params: query
  })
}

export function addPagesTemplate (query) {
  return fetch({
    url: '/pagestemplate/add',
    method: 'post',
    data: query
  })
}

export function addPagesTemplateRelCity (query) {
  return fetch({
    url: '/pagestemplate/city/add',
    method: 'post',
    data: query
  })
}

export function delPagesTemplateRelCity (query) {
  return fetch({
    url: '/pagestemplate/city/del',
    method: 'post',
    data: query
  })
}

export function savePagesTemplate (query) {
  return fetch({
    url: '/pagestemplate/edit',
    method: 'put',
    data: query
  })
}

export function copyPagesTemplate (query) {
  return fetch({
    url: '/pagestemplate/copy',
    method: 'post',
    data: query
  })
}

export function deletePagesTemplate (id) {
  return fetch({
    url: '/pagestemplate/del/' + id,
    method: 'delete'
  })
}

export function getPagesTemplateDetail (query) {
  return fetch({
    url: '/pagestemplate/detail',
    method: 'get',
    params: query
  })
}
export function getPagesContentList (query) {
  return fetch({
    url: '/pagestemplate/content_list',
    method: 'get',
    params: query
  })
}

export function syncPagesTemplate (query) {
  return fetch({
    url: '/pagestemplate/sync',
    method: 'put',
    data: query
  })
}

export function modifyPagesTemplateStatus (query) {
  return fetch({
    url: '/pagestemplate/modifyStatus',
    method: 'put',
    data: query
  })
}

export function getExamineList (query) {
  return fetch({
    url: '/pagestemplate/examine/list',
    method: 'get',
    params: query
  })
}

export function submitExamineTemplate (query) {
  return fetch({
    url: '/pagestemplate/examine',
    method: 'post',
    data: query
  })
}

export function examineTemplate (query) {
  return fetch({
    url: '/pagestemplate/examine',
    method: 'put',
    data: query
  })
}

export function getTemplatePreviewQrCode (query) {
  return fetch({
    url: '/pagestemplate/preview',
    method: 'get',
    params: query
  })
}

export function getDistributorPagesTemplateList (query) {
  return fetch({
    url: '/distributortemplatedisti/pagestemplate/lists',
    method: 'get',
    params: query
  })
}

export function getDistributorExamineList (query) {
  return fetch({
    url: '/distributortemplatedisti/pagestemplate/examine/list',
    method: 'get',
    params: query
  })
}

export function examineDistributorTemplate (query) {
  return fetch({
    url: '/distributortemplatedisti/pagestemplate/examine',
    method: 'put',
    data: query
  })
}

export function getDistributorTemplatePreviewQrCode (query) {
  return fetch({
    url: '/distributortemplatedisti/pagestemplate/preview',
    method: 'get',
    params: query
  })
}

export function getShowRuleList (query) {
  return fetch({
    url: '/distributor/showRule/list',
    method: 'get',
    params: query
  })
}

export function getCustomModuleBindTemplate (query) {
  return fetch({
    url: '/pagestemplate/customModule/template/list',
    method: 'get',
    params: query
  })
}

export function revertExamineTemplate (query) {
  return fetch({
    url: '/pagestemplate/examine/revert',
    method: 'post',
    data: query
  })
}

export function getcustomTemrExamineList (query) {
  return fetch({
    url: '/pagestemplate/examine/list',
    method: 'get',
    params: query
  })
}

export function updateReplaceTime (query) {
  return fetch({
    url: '/pagestemplate/examine/update_replace_time',
    method: 'post',
    data: query
  })
}

export function getwebSections (query) {
  return fetch({
    url: 'third/sensors/web_sections',
    method: 'get',
    params: query
  })
}

//创建/修改营销
export function createOrUpdateMarketing (query) {
  return fetch({
    url: 'navigation/promotion',
    method: 'post',
    data: query
  })
}
//删除营销
export function deleteMarketing (query) {
  return fetch({
    url: 'navigation/promotion',
    method: 'delete',
    params: query
  })
}