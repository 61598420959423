import fetch from '../utils/fetch'

export function getShippingTemplatesList (query) {
  return fetch({
    url: '/shipping/templates/list',
    method: 'get',
    params: query
  })
}

export function getShippingTemplatesInfo (id) {
  return fetch({
    url: '/shipping/templates/info/' + id,
    method: 'get'
  })
}

export function createShippingTemplates (params) {
  return fetch({
    url: '/shipping/templates/create',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: params
  })
}

export function updateShippingTemplates (id, params) {
  return fetch({
    url: '/shipping/templates/update/' + id,
    method: 'put',
    data: params
  })
}

export function deleteShippingTemplates (id) {
  return fetch({
    url: '/shipping/templates/delete/' + id,
    method: 'delete'
  })
}

// 更新市场分类
export function getGoodsOmsinfo (params) {
  return fetch({
    url: '/goods/omsinfo/' + params.item_id,
    method: 'get'
  })
}


