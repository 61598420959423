import fetch from '../utils/fetch'

// 创建物料
export function setMaterial (query) {
  return fetch({
    url: '/material',
    method: 'post',
    data: query
  })
}

// 导入渠道
export function setChannelParamsUpload (query) {
  return fetch({
    url: '/material/tempChannelParams/upload',
    method: 'post',
    // headers: {'Content-Type': 'multipart/form-data'},
    data: query
  })
}

// 获取渠道列表
export function getTempChannelParams (query) {
  return fetch({
    url: '/material/tempChannelParams',
    method: 'get',
    params: query
  })
}

// 添加临时渠道
export function setTempChannelParams (query) {
  return fetch({
    url: '/material/tempChannelParams',
    method: 'post',
    data: query
  })
}

// 删除临时渠道
export function deleteTempChannelParams (query) {
  return fetch({
    url: '/material/tempChannelParams',
    method: 'delete',
    params: query
  })
}

// 物料明细列表
export function getDeleteTempDetailList (query) {
  return fetch({
    url: '/material/detail/list',
    method: 'get',
    params: query
  })
}

// 物料生成记录
export function getMaterialList (query) {
  return fetch({
    url: '/material/list',
    method: 'get',
    params: query
  })
}

// 更新物料失效时间
export function getMaterialExpireTime (query) {
  return fetch({
    url: '/material/expireTime',
    method: 'put',
    params: query
  })
}

// 作废物料明细
export function getMaterialDetailCancel (query) {
  return fetch({
    url: '/material/detail/cancel',
    method: 'post',
    data: query
  })
}

// 导入店铺
export function getRelDistributorUpload (query) {
  return fetch({
    url: '/material/relDistributor/upload',
    method: 'post',
    data: query
  })
}

// 作废物料
export function getMaterialCancel (query) {
  return fetch({
    url: '/material/cancel',
    method: 'post',
    data: query
  })
}

// 获取临时店铺列表
export function getRelDistributorTempList (query) {
  return fetch({
    url: '/material/relDistributor/tempList',
    method: 'get',
    params: query
  })
}