import fetch from '../utils/fetch'

export function activate (query) {
  return fetch({
    url: '/company/activate',
    method: 'post',
    data: query
  })
}

export function getActivateInfo () {
  return fetch({
    url: '/company/activate',
    method: 'get',
  })
}
export function ydleadsInfo (data) {
  return fetch({
    url: '/ydleads/create',
    method: 'post',
    data
  })
}

export function getResourceList (query) {
  return fetch({
    url: '/company/resources',
    method: 'get',
    params: query
  })
}

export function updateCompanyInfo (query) {
  return fetch({
    url: '/company',
    method: 'patch',
    data: query
  })
}

//以下为账号管理接口

export function createAccount (query) {
  return fetch({
    url: '/account/management',
    method: 'post',
    data: query
  })
}

export function getAccountInfo (id) {
  return fetch({
    url: '/account/management/' + id,
    method: 'get',
  })
}

export function getAccountList (query) {
  return fetch({
    url: '/account/management',
    method: 'get',
    params: query
  })
}

export function updateAccountInfo (id, query) {
  return fetch({
    url: '/account/management/' + id,
    method: 'patch',
    data: query
  })
}

export function deleteAccountInfo(id){
  return fetch({
    url: '/account/management/' + id,
    method: 'delete'
  })
}

//以下为角色管理接口

export function createRoles (query) {
  return fetch({
    url: '/roles/management',
    method: 'post',
    data: query
  })
}

export function getRolesInfo (id) {
  return fetch({
    url: '/roles/management/'+id,
    method: 'get',
  })
}

export function getRolesList (query) {
  return fetch({
    url: '/roles/management',
    method: 'get',
    params: query
  })
}

export function updateRolesInfo (id, query) {
  return fetch({
    url: '/roles/management/'+ id,
    method: 'patch',
    data: query
  })
}

export function deleteRole (id) {
  return fetch({
    url: '/roles/management/'+id,
    method: 'delete',
  })
}
export function saveRolesGroup (query) {
  return fetch({
    url: '/roles/management/group',
    method: 'put',
    data: query
  })
}

export function getPermissionList (query) {
  return fetch({
    url: '/permission',
    method: 'get',
    params: query
  })
}

export function createCurrencyInfo (query) {
  return fetch({
    url: '/currency',
    method: 'post',
    data: query
  })
}

export function deleteCurrencyInfo (id) {
  return fetch({
    url: '/currency/'+id,
    method: 'delete',
  })
}

export function updateCurrencyInfo (id, query) {
  return fetch({
    url: '/currency/' + id,
    method: 'put',
    data: query
  })
}

export function getCurrencytLists (query) {
  return fetch({
    url: '/currency',
    method: 'get',
    params: query
  })
}
export function getCurrencytInfo (id) {
  return fetch({
    url: '/currency/' +id,
    method: 'get',
  })
}

export function setDefaultCurrency(id) {
  return fetch({
    url: '/currencySetDefault/' + id,
    method: 'put',
  })
}

export function getDefaultCurrency() {
  return fetch({
    url: '/currencyGetDefault',
    method: 'get'
  })
}

export function setCompanySetting(query) {
  return fetch({
    url: '/company/setting',
    method: 'post',
    data: query
  })
}

export function getCompanySetting() {
  return fetch({
    url: '/company/setting',
    method: 'get'
  })
}

export function getCompanyStatistics(query) {
  return fetch({
    url: '/getStatistics',
    method: 'get',
    params: query
  })
}

export function getSelfDeliveryAddress() {
  return fetch({
    url: '/setting/selfdelivery',
    method: 'get'
  })
}

export function setSelfDeliveryAddress(query) {
  return fetch({
    url: '/setting/selfdelivery',
    method: 'post',
    data: query
  })
}

export function shopLoginSelectShopId(query) {
  return fetch({
    url: '/operator/select/distributor',
    method: 'post',
    data: query
  })
}

export function getCompanysLogs(query) {
  var object = fetch({url: '/company/operatorlogs', method: 'get', params: query})
  return object.then(res => {
    return res.data.data
  })
}

export function getCompanysMongoLogs(query) {
  var object = fetch({url: '/company/mongologs', method: 'get', params: query})
  return object.then(res => {
    return res.data.data
  })
}

export function getCertificate() {
  return fetch({
    url: '/third/saascert/certificate',
    method: 'get'
  })
}

export function deleteCertificateInfo() {
  return fetch({
    url: '/third/saascert/delete/certificate',
    method: 'get'
  })
}

export function bindRelation() {
  return fetch({
    url: '/third/saascert/apply/bindrelation',
    method: 'get'
  })
}

export function acceptRelation() {
  return fetch({
    url: '/third/saascert/accept/bindrelation',
    method: 'get'
  })
}

export function getWebUrlSetting() {
  return fetch({
    url: '/setting/weburl',
    method: 'get'
  })
}

export function saveWebUrlSetting(query) {
  return fetch({
    url: '/setting/weburl',
    method: 'post',
    data: query
  })
}


export function gettooluat() {
  return fetch({
    url: '/setting/gettooluat',
    method: 'get'
  })
}

export function savetooluat(query) {
  return fetch({
    url: '/setting/savetooluat',
    method: 'post',
    data: query
  })
}
export function kefuToolPush(query) {
  return fetch({
    url: '/setting/kefuToolPush',
    method: 'post',
    data: query
  })
}

export function mtApiSetting() {
  return fetch({
    url: '/setting/mtApiSetting',
    method: 'get',
  })
}




export function getRateSetting(){
  return fetch({
    url: '/traderate/setting',
    method: 'get',
  })
}
export function setRateSetting(query){
  return fetch({
    url: '/traderate/setting',
    method: 'post',
    data: query
  })
}
export function getShareSetting(){
  return fetch({
    url: '/share/setting',
    method: 'get',
  })
}
export function setShareSetting(query){
  return fetch({
    url: '/share/setting',
    method: 'post',
    data: query
  })
}

export function getWhitelistSetting(){
  return fetch({
    url: '/member/whitelist/setting',
    method: 'get',
  })
}
export function setWhitelistSetting(query){
  return fetch({
    url: '/member/whitelist/setting',
    method: 'post',
    data: query
  })
}

export function getPickupcodeSetting(){
  return fetch({
    url: '/pickupcode/setting',
    method: 'get',
  })
}
export function setPickupcodeSetting(query){
  return fetch({
    url: '/pickupcode/setting',
    method: 'post',
    data: query
  })
}

export function getGiftSetting(){
  return fetch({
    url: '/gift/setting',
    method: 'get',
  })
}
export function setGiftSetting(query){
  return fetch({
    url: '/gift/setting',
    method: 'post',
    data: query
  })
}

export function getSendOmsSetting(){
  return fetch({
    url: '/sendoms/setting',
    method: 'get',
  })
}
export function setSendOmsSetting(query){
  return fetch({
    url: '/sendoms/setting',
    method: 'post',
    data: query
  })
}

export function getNoStores(){
  return fetch({
    url: '/nostores/setting',
    method: 'get',
  })
}
export function setNoStores(query){
  return fetch({
    url: '/nostores/setting',
    method: 'post',
    data: query
  })
}


export function getRechargeSetting(){
  return fetch({
    url: '/recharge/setting',
    method: 'get',
  })
}
export function setRechargeSetting(query){
  return fetch({
    url: '/recharge/setting',
    method: 'post',
    data: query
  })
}

export function getRepeatCancelSetting(){
  return fetch({
    url: '/trade/cancel/setting',
    method: 'get',
  })
}
export function setRepeatCancelSetting(query){
  return fetch({
    url: '/trade/cancel/setting',
    method: 'post',
    data: query
  })
}

export function getCityList(query){
  return fetch({
    url: '/espier/citylist',
    method: 'get',
    params: query
  })
}

export function exportCityData(query){
  return fetch({
    url: '/espier/citylist/export',
    method: 'get',
    params: query
  })
}

export function getHotCity(){
  return fetch({
    url: '/espier/hotcity',
    method: 'get'
  })
}

export function setHotCity(query){
  return fetch({
    url: '/espier/hotcity',
    method: 'post',
    data: query
  })
}

export function getCitiesInfo(query){
  return fetch({
    url: '/espier/citiesInfo',
    method: 'get',
    params: query
  })
}

export function getAnnouncement() {
  return fetch({
    url: '/espier/announcement',
    method: 'get'

  })
}

export function setAnnouncement(query){
  return fetch({
    url: '/espier/set_announcement',
    method: 'post',
    data: query
  })
}

export function getIupAgreement() {
  return fetch({
    url: '/espier/iup_agreement',
    method: 'get'

  })
}

export function setIupAgreement(query){
  return fetch({
    url: '/espier/iup_agreement',
    method: 'post',
    data: query
  })
}
export function updateDefault(query){
  return fetch({
    url: '/espier/iup_agreement_default',
    method: 'put',
    data: query
  })
}

export function  getShortLink(query){
  return fetch({
    url: '/espier/getShortLink',
    method: 'post',
    data: query
  })
}


export function getGroupLists(query) {
  return fetch({
    url: '/roles/groupLists',
    method: 'get',
    params: query

  })
}
export function getGroupDetail(query) {
  return fetch({
    url: '/roles/groupDetail',
    method: 'get',
    params: query
  })
}
export function setGroup(query) {
  return fetch({
    url: '/roles/saveGroup',
    method: 'post',
    data: query
  })
}
export function deleteGroup(query){
  return fetch({
    url: '/roles/group',
    method: 'delete',
    params: query
  })
}
export function getGroupBindRoles(query){
  return fetch({
    url: '/roles/group/bind',
    method: 'get',
    params: query
  })
}
